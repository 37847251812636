import React from "react";
import ImageGridItem from "./ImageGridItem";
import ImageGridItemNoLink from "./ImageGridItemNoLink";

const ImageGrid = ({ Column, items, routePrefix, noLinks }) => {
  return (
    <>
      <div className="row row--15" style={{ justifyContent: "center" }}>
        {items.map((item) => (
          <div key={item.id} className={Column}>
            {noLinks ? (
              <ImageGridItemNoLink portfolio={item} routePrefix={routePrefix} />
            ) : (
              <ImageGridItem portfolio={item} routePrefix={routePrefix} />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ImageGrid;
