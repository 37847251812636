import React from "react";
import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import * as styles from "./ImageGrid.module.scss";
const ImageGridItemNoLink = ({ portfolio, routePrefix }) => {
  const { caption, ...posterData } = portfolio.poster ?? {};

  return (
    <div className={styles.rwtCard}>
      <div className={styles.inner}>
        <div className={styles.thumbnail}>
          <figure className={styles.cardImage}>
            {portfolio.poster && (
              <SanityImage
                {...posterData}
                width={300}
                height={300}
                alt={caption}
                className="img-fluid"
              />
            )}
          </figure>
        </div>
        <div className={styles.content}>
          <h5 className={`${styles.title} mb--10`}>{portfolio.title}</h5>
          <span className={`${styles.subtitle} b2`}>
            {portfolio.description}
          </span>
        </div>
      </div>
    </div>
  );
};
export default ImageGridItemNoLink;
