import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbBanner from "../components/BreadcrumbBanner";
import ImageGrid from "../components/ImageGrid/ImageGrid";
import * as React from "react";
import { graphql } from "gatsby";
// import Separator from "../components/Separator";
import SectionTitle from "../components/SectionTitle";

import "./products.scss";
import { ComparisonTable } from "../components/ComparisonTable";

const itemsDynamic = [
  {
    title: "Lite",
    startingPrice: 800,
    features: [
      {
        icon: "yes",
        label: "Modular",
      },
      {
        icon: "yes",
        label: "Printed Fabric Front",
      },
      {
        icon: "no",
        label: "Printed Fabric Back",
      },
      {
        icon: "yes",
        label: "No size constraints",
      },
      {
        icon: "yes",
        label: "Internal lighting",
      },
      {
        icon: "yes",
        label: "Animated LEDs",
      },
      {
        icon: "no",
        label: "HD LEDs",
      },
      {
        icon: "no",
        label: "RGB LEDs",
      },
      {
        icon: "no",
        label: "Sound",
      },
      {
        icon: "no",
        label: "Remote Control",
      },
    ],
    buyLink: "/products/huebox-lite",
  },
  {
    title: "Pro",
    startingPrice: 1000,
    features: [
      {
        icon: "yes",
        label: "Modular",
      },
      {
        icon: "yes",
        label: "Printed Fabric Front",
      },
      {
        icon: "no",
        label: "Printed Fabric Back",
      },
      {
        icon: "yes",
        label: "No size constraints",
      },
      {
        icon: "yes",
        label: "Internal lighting",
      },
      {
        icon: "yes",
        label: "Animated LEDs",
      },
      {
        icon: "yes",
        label: "HD LEDs",
      },
      {
        icon: "no",
        label: "RGB LEDs",
      },
      {
        icon: "no",
        label: "Sound",
      },
      {
        icon: "no",
        label: "Remote Control",
      },
    ],
    buyLink: "/products/huebox-pro",
  },
  {
    title: "Pro RGB",
    startingPrice: 1200,
    features: [
      {
        icon: "yes",
        label: "Modular",
      },
      {
        icon: "yes",
        label: "Printed Fabric Front",
      },
      {
        icon: "no",
        label: "Printed Fabric Back",
      },
      {
        icon: "yes",
        label: "No size constraints",
      },
      {
        icon: "yes",
        label: "Internal lighting",
      },
      {
        icon: "yes",
        label: "Animated LEDs",
      },
      {
        icon: "yes",
        label: "HD LEDs",
      },
      {
        icon: "yes",
        label: "RGB LEDs",
      },
      {
        icon: "yes",
        label: "Sound",
      },
      {
        icon: "yes",
        label: "Remote Control",
      },
    ],
    buyLink: "/products/huebox-prorgb",
  },
];
const ProductsPage = ({ data }) => {
  const heroImage = data.heroImage.childImageSharp.original.src;

  return (
    <>
      <SEO title="Products" />
      <Layout>
        <div
          className="slider-area slider-style-1 variation-default height-450 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${heroImage})`,
            justifyContent: "center",
          }}
        >
          <BreadcrumbBanner
            title="Products"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Products"
          />
        </div>

        <div className="main-content">
          <div className="container">
            <div className="rn-section-gap">
              <p>
                HueBox is the ultimate solution for any display environment
                where attracting attention and standing out is key. With its
                modular aluminium frame and stretched silicon edged fabric
                graphic, HueBox is perfect for a wide range of sectors including
                retail, events, airports, travel, and health & fitness.
              </p>
              <p>
                All HueBox systems are configured to use bespoke animated
                content created specifically for the full-frame graphic being
                showcased, using advanced techniques to bring out the most
                important features of your brand or message. This allows you to
                create a truly unique and eye-catching display that is sure to
                turn on attention and leave a lasting impression.
              </p>
              <p>
                One of the major benefits of HueBox is its cost-effective
                nature. All systems can be deployed once and re-used by simply
                changing the printed graphic and updating the animation through
                the built-in USB/SD card reader. This means you can easily
                update your display to reflect new products, promotions, or
                events without incurring the cost of purchasing a brand new
                system.
              </p>
              <p>
                If you're interested in incorporating HueBox into your display,
                we have a range of resellers nationwide who can help you find
                the perfect solution for your needs. Contact us today to learn
                more.
              </p>
            </div>
            <ComparisonTable items={itemsDynamic} />
          </div>
          <div className="rn-section-gap">
            <SectionTitle
              textAlign="text-center"
              radiusRounded=""
              subtitle=""
              title="Find out more"
              // description="To discuss a bespoke project, or find out more information about our products, please get in touch!"
            />
            <div className="rwt-elements-area rn-section-gap">
              <div className="container plr--30">
                <ImageGrid
                  noLinks
                  routePrefix="/products"
                  Column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay"
                  items={data.allSanityProduct.nodes}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default ProductsPage;

export const query = graphql`
  {
    heroImage: file(
      relativePath: { eq: "Abstract_RGB_Board_Flip_Curtain_White.jpg" }
    ) {
      childImageSharp {
        original {
          src
        }
        #        gatsbyImageData(
        #          width: 1920
        #          quality: 80
        #          placeholder: NONE
        #          layout: CONSTRAINED
        #        )
      }
    }
    allSanityProduct {
      nodes {
        id
        handle {
          current
        }
        title
        description
        poster {
          asset {
            _id
          }
          crop {
            bottom
            left
            right
            top
          }
          hotspot {
            height
            width
            x
            y
          }
          caption
        }
      }
    }
  }
`;
