import React from "react";
import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import * as styles from "./ImageGrid.module.scss";
const ImageGridItem = ({ portfolio, routePrefix }) => {
  const { caption, ...posterData } = portfolio.poster ?? {};

  return (
    <div className={styles.rwtCard}>
      <div className={styles.inner}>
        <div className={styles.thumbnail}>
          <figure className={styles.cardImage}>
            <Link to={`${routePrefix ?? ""}/${portfolio.handle.current}`}>
              {portfolio.poster && (
                <SanityImage
                  {...posterData}
                  width={300}
                  height={300}
                  alt={caption}
                  className="img-fluid"
                />
              )}
            </Link>
          </figure>
          <Link
            to={`${routePrefix ?? ""}/${portfolio.handle.current}`}
            className={styles.rwtOverlay}
          ></Link>
        </div>
        <div className={styles.content}>
          <h5 className={`${styles.title} mb--10`}>
            <Link to={`${routePrefix ?? ""}/${portfolio.handle.current}`}>
              {portfolio.title}
            </Link>
          </h5>
          <span className={`${styles.subtitle} b2`}>
            {portfolio.description}
          </span>
        </div>
      </div>
    </div>
  );
};
export default ImageGridItem;
